@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.ride-search {
    padding: cssvar(spacing-4);
    margin: -20px 0 0 0;
    border: 1px solid cssvar(primary-line-color);
    background-color: cssvar(primary-bg-color);

    &__content-block {
        position: relative;
    }

    &__filters-pane {
        padding: 21px 0 0;
        background: #ffffff;
        border-radius: 5px;
        z-index: 100;

        [class*='autocomplete'] {
            // prevent label goes in two lines
            label {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    &__main-panel {
        display: flex;
    }

    &__multiple-input {
        width: 80%;
        max-width: 1050px;

        @media (max-width: 1200px) {
            width: 70%;
        }
    }

    &__filter-button {
        width: 8%;

        @media (max-width: 1200px) {
            width: 13%;
        }
    }

    &__search-button {
        width: 12%;

        @media (max-width: 1200px) {
            width: 15%;
        }
    }

    &__checkbox-filters {
        padding-top: 6px;
    }
}

.rides-list {
    padding: cssvar(spacing-4);
    background: cssvar(grayscale-10-color);
    min-height: calc(
        100vh - 320px
    ); // 60px header height and 260px rides-search height
}

.rs-calendar {
    position: relative;

    &__wrapper {
        position: absolute;
        width: auto;
        top: 60px;
        left: 0;
        z-index: z('dropdown');
    }
}

.skeleton-add-m {
    margin-bottom: 12px;
}

.date-shortcuts {
    font-size: 14px;

    button {
        background: none;
        border: none;
        padding: 0;
        margin: 4px;
        color: cssvar(neutral-color);
        cursor: pointer;
        outline: 0;

        &:hover {
            text-decoration: underline;
        }
    }
}

.multi-picker-tag {
    border-color: cssvar(grayscale-30-color);
    color: cssvar(grayscale-100-color);
    margin-top: cssvar(spacing-2);
    margin-right: cssvar(spacing-1);

    &__icon {
        width: 0.8751em;
        margin-left: 5px;
        margin-top: 4px;
        fill: cssvar(grayscale-70-color);
    }
}

.time-input-white-bg {
    background: cssvar(primary-bg-color);
}

legend[class*='label'] {
    padding: 0 0 2px;
    color: cssvar(secondary-content-color);
    font-size: cssvar(font-size-primary);
    line-height: cssvar(line-height-primary);
}

.ride-search-text {
    &--left {
        text-align: left;
    }
    &--right {
        text-align: right;
    }
}

.ride-search-results-info {
    padding: cssvar(spacing-4) cssvar(spacing-4) 0;
}

.ride-search-custom-autocomplete {
    $xs: 12px; //cssvar(spacing-2);
    $md: 36px; //cssvar(spacing-6);
    $lg: 48px; //cssvar(spacing-8);

    [class^='hcr-autocomplete-selected-list__btn-reset'] {
        display: none;
    }
    [class^='hcr-autocomplete__dropdown'] {
        top: 100%;
    }
    [class^='hcr-autocomplete-selected-list-'] {
        position: absolute;
        width: 100%;
        top: -$xs;
        left: 0;
        padding-right: 150px; // max width of the reset button in all languages
    }

    &--with-tags {
        margin-top: cssvar(spacing-6);
    }
    &--two-rows {
        margin-top: $md * 2;

        [class^='hcr-autocomplete-selected-list-'] {
            top: calc(
                -1px - (#{$lg} + #{$md}) + 1px
            ); // -1 and +1 is for negative calc value
        }
    }

    &__reset {
        position: absolute;
        bottom: 45px; // to better align with the design
        right: 0;
    }
}

.ride-search-suggestions-option {
    font-family: cssvar(font-family-primary);
    font-size: cssvar(font-size-primary);
    font-weight: cssvar(font-weight-normal);
    line-height: cssvar(line-height-small);
    color: cssvar(primary-content-color);
    margin: 0;
    &__title {
        display: block;
        font-weight: cssvar(font-weight-semibold);
    }
}

.load-more-wrapper {
    padding: cssvar(spacing-4) 0;
    text-align: center;
}

.results-header {
    display: flex;
    padding: 0 0 0 cssvar(spacing-2);
}

.select-results-checkbox {
    margin-left: 14px;
    margin-right: cssvar(spacing-6);
}

.expand-results-toggle {
    cursor: pointer;
}

.rs-combo-picker {
    position: relative;

    &__left,
    &__right {
        display: inline-block;
    }
    &__left {
        width: 51%;

        [class*='input__container'] {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-right: 24px;

            &:focus {
                border-right: none;
            }
        }
    }
    &__right {
        width: 49%;

        [class*='input__container'] {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:focus {
                border-left: none;
            }
        }
    }
    &__switch {
        position: absolute;
        top: 50%;
        left: 49%;
        height: 32px;
        width: 32px;
        margin-top: -4px;
        text-align: center;
        line-height: 30px;
        border: 1px solid cssvar(primary-line-color);
        border-radius: 100%;
        background-color: cssvar(primary-bg-color);
        transform: translate(-50%) rotate(0deg);
        cursor: pointer;
        transition: transform 0.4s;

        &--rotate {
            transform: translate(-50%) rotate(180deg);
        }
    }
}

.ride-search-results-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: cssvar(spacing-2);

    &__dropdown {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.fake-select {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    font-weight: normal;
    color: cssvar(secondary-content-color);
    margin-right: cssvar(spacing-2);
    padding: 0 cssvar(spacing-2);
    border: 1px solid cssvar(input-border-color);
    border-radius: cssvar(input-border-radius);
    padding-right: cssvar(spacing-1);
    background: transparent;
}

.background-post-form {
    visibility: hidden;
    position: absolute;
    top: -10000px;
}

.ride-search-results__select-all {
    font-size: cssvar(font-size-primary);
    font-weight: cssvar(font-weight-semibold);
    height: auto;
    line-height: cssvar(line-height-primary);
    margin-left: cssvar(spacing-2);
}
