@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

/*
    Due Safari does not apply box-shadow
    to the <tr> have to specify for <td> instead
*/

@mixin item {
    display: flex;
    align-items: center;
    line-height: 24px;
    margin: 1px 0 0;
    padding: 0 0 1px 0;
    min-height: 29px;
    border-bottom: 1px solid transparent;
}

.reservation-passenger-row .flix-data-grid-col {
    border: 1px solid cssvar(grayscale-10-color);
}
.reservation-passenger-row:hover {
    background-color: rgba(180, 180, 180, 0.2);
}

.reservation-passenger-row:last-of-type {
    border-bottom: none;
}

.reservation-order-info {
    border-right: 1px solid cssvar(grayscale-30-color);
}

.reservation-passenger-item {
    @include item;

    @media (max-width: 1400px) {
        &__name {
            display: none;
        }
        &__name-wrapper:after {
            content: ' (...)';
            display: inline-block;
        }
    }

    &--push {
        margin-left: 30px;
        border-bottom: 1px solid cssvar(grayscale-30-color);

        &:last-child {
            border-bottom: none;
        }

        @media (max-width: 1400px) {
            margin-left: 32px;
        }
    }
}

.reservation-passenger-push-value {
    @include item;

    & + .reservation-passenger-push-value {
        border-bottom: 1px solid cssvar(grayscale-30-color);
    }

    &.space-around {
        display: flex;
        justify-content: space-around;
    }

    &:last-of-type {
        border-bottom: 1px solid transparent;
    }
}

.reservation-passenger-intercon {
    color: cssvar(link-color);
    text-decoration: none;
}

/* custom gender icons */
.flix-icon--passenger-gender-icon {
    width: 20px;
    vertical-align: middle;
}

.reservation-passengers-tag {
    &--absent {
        color: cssvar(danger-color);
        border-color: cssvar(danger-color);
    }

    &--checked {
        color: cssvar(success-color);
        border-color: cssvar(success-color);
    }
}

.reservation-passenger-icon--checked svg {
    fill: cssvar(primary-brand-color);
}

.reservation-total-info {
    padding: cssvar(spacing-3);
}

.reservation-total-info__item {
    display: flex;
    justify-content: space-between;
}

.sort-handler-wrapper {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    margin: 0 -5px 0 0; /* this is to reduce the "seat" column width */

    &__del {
        margin: 0 4px;
    }

    & > span:first-of-type:not(:only-child) {
        margin-right: -6px;
    }
}

.sort-handler {
    cursor: pointer;
}

@media (max-width: 1400px) {
    .reservation-passenger-email,
    .reservation-passenger-phone {
        font-size: 15px;
    }
}

.driver-role:first-letter {
    text-transform: capitalize;
}

.reservation-passenger-email {
    & > span {
        margin-right: cssvar(spacing-1);
    }

    svg {
        fill: cssvar(primary-brand-color);
    }

    &--highlight {
        color: cssvar(danger-dark-color);
    }
}

.reservation-passenger-push {
    vertical-align: top;
    padding-top: 3px;

    .reservation-passenger__pickup-locations:first-of-type {
        padding-top: 8px;
    }

    &--status {
        vertical-align: middle;

        .reservation-passenger-push-value:first-of-type {
            border-color: transparent;
        }
    }
}

.reservation-passenger-spacer {
    @include item;
}

.reservation-passenger {
    &-seat-badge {
        font-size: 8px;
        font-weight: cssvar(font-weight-semibold);
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-left: 2px;
        line-height: 11px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid cssvar(grayscale-100-color);
        color: cssvar(grayscale-100-color);
        vertical-align: super;

        &--passenger {
            color: cssvar(grayscale-100-color);
            border-color: cssvar(grayscale-100-color);
            &:before {
                content: 'P';
            }
        }
        &--auto_assign {
            color: cssvar(warning-color);
            border-color: cssvar(warning-color);
            &:before {
                content: 'A';
            }
        }
        &--backend_manual {
            &:before {
                content: 'M';
            }
        }
        &--empty {
            font-size: 16px;
            width: 18px;
            height: 18px;
            line-height: 16px;
            margin-top: 8px;

            &:before {
                content: 'E';
            }
        }
    }
}

.extra-icon {
    width: cssvar(spacing-4);
    height: cssvar(spacing-4);

    svg {
        max-width: 100%;
        height: auto;
    }
}

.redubs {
    color: cssvar(danger-color);
    font-weight: bold;
    padding-left: cssvar(spacing-1);
}

.paytm {
    color: #0055cc;
    font-weight: bold;
    padding-left: cssvar(spacing-1);
}

.abhibus {
    color: #ff991f;
    font-weight: bold;
    padding-left: cssvar(spacing-1);
}
