.sub-navigation {
    margin: -17px 0 4px; // temporary negative margin untill the whole page layout will be updated
    transition: position 0.25s ease-in-out;

    & > ul {
        justify-content: center;
    }
    .hat-wrapper {
        position: relative;
        img {
            position: absolute;
            width: 20px;
            height: auto;
            top: -9px;
            right: -13px;
            transform: rotate(58deg);
        }
    }
}
