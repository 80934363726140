.language-switcher__button {
    display: flex;
    font-size: 14px;
    color: inherit;

    background: none;
    border: 0;
    cursor: pointer;
    align-items: center;
}
.language-switcher_img {
    display: block;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #353535;
}
.language-switcher .language-switcher_img {
    margin-left: -4px;
}
