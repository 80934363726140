$content-max-width: 1318px;

.rv-search-panel {
  &__header {
    width: 100%;
    padding: 10px 24px;
    color: black;
    font-size: 20px;
    max-width: $content-max-width;
    margin: 0 auto;
    text-align: left;
  }

  &__content {
    max-width: $content-max-width;
    margin: 0 auto 20px auto;
  }

  &__footer {
    max-width: $content-max-width;
    margin: 0 auto 10px auto;
  }
}