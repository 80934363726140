.passenger-comments-action {
    cursor: pointer;
}

.last-comment-content {
    max-height: 44px;
    overflow: hidden;
}

.last-comment-info {
    margin-top: 5px;
    font-size: 80%;
    min-width: 220px;
}

.comets-total-count {
    text-align: right;
    float: right;
}

.comment-content {
    padding: 10px 0 10px 80px; /* space for side panel */
}

@media (max-width: 1400px) {
    .comment-content {
        padding-left: 0;
    }
}

.comment-content__form {
    width: 85%;
}
