@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.bar-box {
    background: cssvar(primary-bg-color);
    border: 1px solid cssvar(grayscale-30-color);
    box-sizing: border-box;
    border-radius: 5px;
    padding: cssvar(spacing-4) cssvar(spacing-2) 20px cssvar(spacing-3);
    margin-bottom: 12px;

    &--with-hover {
        &:hover {
            background: #e5f9c0;
        }
    }
}
