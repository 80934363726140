.ride-view-switcher {
    position: relative;
}

.ride-view-switcher__spinner-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 41px;
    height: 100%;
    margin-top: -4px;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 5;
}
