@mixin print {
    // print overrides
    .rideviewer-main-wrapper {
        width: 9.5in; // A4 Letter
        min-height: 11in;
        padding: 0 2rem 2rem 2rem;
        max-width: 1000px;
        margin: 2rem auto;
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
        background: white;
        overflow: hidden;
    }
    .ride-view__context-block--narrow {
        max-width: 100%;
        overflow: hidden;
    }
    .ride-view {
        &__site-content {
            margin: 0;
            padding: 0;
        }
    }
    .ride-info {
        &__del {
            margin: 0;
            border: 0;
        }
        &__secondary-info--title {
            font-size: 16px;
        }
    }
    .ride-info-tag {
        border: 0;
        font-weight: bold;
        zoom: 1.3;
        padding: 0;
        margin: 0;
        vertical-align: top;
    }
    .bar-box {
        border: 0;
        padding: 0;
        box-shadow: none;
        background: none;
        zoom: 0.9;
    }
    .ride-info-tags {
        & > div:first-of-type {
            display: flex;
            height: 100%;
            align-items: center;
        }
    }
    .reservation-total-info {
        padding: 10px;
        overflow: hidden;
        width: 10rem;

        &__item {
            float: left;
        }
    }
    .content-box-item {
        padding: 0;
        overflow: hidden;
    }
    .content-box-items {
        min-height: auto;
    }
    .sort-handler-wrapper {
        margin: 0 0 0 -5px;
    }
    .content-box {
        display: block;
    }
    .content-box-components {
        width: 100%;
    }
    .timeline-bottom-wrapper {
        width: 100%;
        margin: 0 0 1rem 0;

        & > div {
            padding: 1rem;
        }
    }
    .rideview-taurus-widget-wrapper {
        width: 100%;

        & > div {
            border: 0;
            padding: 0;
        }

        iframe {
            width: 100%;
        }
    }
    .reservation-passenger-push {
        .reservation-passenger__pickup-locations:first-of-type {
            padding-top: 6px;
        }
    }

    // print elements styles
    .print-content-block {
        max-width: 100%;
    }
    .print-heading {
        padding: 2rem 0;
    }
    .print-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        zoom: 1.5;
        cursor: pointer;
    }
    .print-action {
        position: absolute;
        top: 1rem;
        right: 3rem;
        zoom: 1.5;
        cursor: pointer;
    }

    // hide from print
    .hide-on-print,
    .flix-data-grid__checkbox,
    .time-line-compact,
    .sort-handler,
    .sub-navigation {
        display: none;
    }
}

// print styles
@media print {
    #root {
        // approximate page width
        width: 8in; // A4 Letter
        min-height: 11in;
        min-width: inherit;
        overflow: visible !important;
        background: white !important;
        height: auto;
        zoom: 1;

        * {
            color: black !important;
            cursor: default !important;
            box-shadow: none !important;
            text-shadow: none !important;
            background-image: none !important;
        }

        tr,
        img {
            page-break-inside: avoid;
            -webkit-region-break-inside: avoid;
        }
        img {
            max-width: 100% !important;
        }
        p,
        h2,
        h3 {
            orphans: 3;
            widows: 3;
        }
        h1,
        h2,
        h3 {
            page-break-after: avoid;
        }

        .page-break-avoid {
            page-break-inside: avoid !important;
            position: relative !important;
        }

        .rideviewer-main-wrapper {
            width: 100%;
            min-height: auto;
            max-width: 100%;
            margin: 0 auto;
            padding: 0;
        }

        .print-close,
        .print-action {
            display: none;
        }

        .print-heading {
            padding: 1rem 0 2rem 0;
        }

        .flix-data-grid {
            position: relative;
            background: white !important; // override total box visibility
            z-index: 1;
        }

        .flix-data-grid-row-group:nth-child(even) {
            background-color: #eee !important; // print safe gray color
        }
    }

    @include print;
}

// show print preview
.print-view {
    background: white !important;

    @include print;
}
