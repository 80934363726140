@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.seat-assignment-dialog {
    &__link {
        color: cssvar(primary-ui-color);
        text-decoration: none;
        font-size: 16px;
    }
    &__dropdown {
        [class*='__dropdown'] {
            top: 0;
        }
    }
    &__seats {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px 0 70px;

        &--not-train {
            max-width: 350px;
            margin: 0 auto;

            .seat-assignment-dialog__seats-list {
                width: 170px;
            }
        }

        &-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 0 10px 0;
        }
        &-label {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 245px;
            padding-right: cssvar(spacing-2);
            text-align: left;

            span:not(.child-icon) {
                min-width: 68px;
                text-align: right;

                & + span {
                    position: absolute;
                    right: 0;
                }
            }

            .child-icon {
                position: absolute;
                left: -27px;
                top: 2px;
            }
        }
        &-list {
            position: relative;
            display: flex;
            width: 267px;
            gap: cssvar(spacing-1);
            align-items: center;
            justify-content: center;
        }
        &-select {
            width: 100%;
            max-width: 115px;

            // first select placeholder to show
            & > select option:first-of-type {
                display: block !important;
            }
        }
        &-remove {
            position: absolute;
            display: inline-block;
            right: -22px;
            top: 50%;
            margin-top: -10px;
            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }
        }
    }
}

.seats-dropdown {
    position: relative;

    &__button {
        justify-content: flex-start;
        opacity: 0.7;
        min-width: 122px;

        .hci-icon {
            margin: -5px 5px 0 0;
        }

        &--coach {
            min-width: 136px;
        }

        &:hover:not(:disabled) {
            opacity: 1;
        }

        &:after {
            content: '';
            background: cssvar(icon-arrow-down) no-repeat center;
            display: inline-block;
            height: cssvar(spacing-4);
            pointer-events: none;
            position: absolute;
            right: cssvar(spacing-1);
            top: 6px;
            vertical-align: middle;
            width: cssvar(spacing-4);
        }
    }

    &__list {
        position: absolute;
        top: -110px;
        right: -46px;
        z-index: 100;
        width: 210px;
        height: 270px;
        overflow: auto;
        margin: 0;
        padding: cssvar(spacing-2) 0;
        background-color: white;
        border-radius: cssvar(primary-border-radius);
        background: cssvar(box-bg-color);
        box-shadow: cssvar(primary-box-shadow);

        li {
            display: block;
            width: 100%;
            height: 28px;
            line-height: 28px;
            padding: 0 cssvar(spacing-1) 0 cssvar(spacing-3);
            margin-bottom: 3px;
            list-style: none;
            text-align: left;

            // better align icon
            span {
                height: 23px !important;
            }

            &.seats-dropdown__list-item {
                &:hover {
                    background-color: cssvar(grayscale-30-color);
                    cursor: pointer;
                }

                &--sep {
                    height: 12px;
                    margin-top: 16px;

                    &:before {
                        content: '';
                        display: block;
                        width: 50%;
                        border-bottom: 1px solid black;
                    }
                }
            }

            &[disabled] {
                opacity: 0.5;
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }
}

.seats-popup {
    [class*='hcr-popup__body'] {
        width: 650px;
        padding: cssvar(spacing-6) cssvar(spacing-1);
    }
}
