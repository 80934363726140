@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.timeline {
    // override from body till we lose Honeycomb static
    font-size: 14px;
    position: relative;
    margin-top: 40px; // reserve place for toolbar
    --rv-stations-width: 189px;
    --rv-segment-width: 80px;
    --rv-interconnection-item-width: 170px;

    hr {
        margin-top: cssvar(spacing-1);
        margin-bottom: cssvar(spacing-1);
    }

    &--scrollable {
        overflow: auto;
    }
}
.timeline-wrapper {
    display: flex;
    align-items: flex-start;
    padding-top: cssvar(spacing-3);
}

.print-view-link {
    color: inherit;
    text-decoration: none;
    svg {
        margin: 0 1px -6px -1px; // better position non honeycomb icon
    }
}
