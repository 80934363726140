@import '@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

$badge-size: (cssvar(spacing-2));

.collaboration {
    color: cssvar(primary-bg-color);
    outline: none;

    &-wrapper {
        margin-right: cssvar(spacing-1);
        position: relative;
        cursor: pointer;
        border: 0;
        background: none;
    }
    &-badged {
        position: absolute;
        text-align: center;
        line-height: cssvar(font-size-small) - 1;
        font-size: cssvar(font-size-small) - 6;
        color: inherit;
        top: -($badge-size / 2);
        right: -($badge-size / 2);
        position: absolute;
        min-width: $badge-size;
        height: $badge-size;
        border-radius: 50%;
        background-color: cssvar(secondary-ui-color);
    }
    &-icon {
        fill: cssvar(primary-bg-color);
    }
}
@keyframes blink {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.1;
    }
}
