.content-box {
    display: flex;
}
.content-box--space-between {
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.content-box--all-center {
    justify-content: center;
    align-items: center;
    height: 100%;
}
.content-box--align-center {
    align-items: center;
    height: 100%;
}
.content-box-menu {
    padding: 20px 15px;
    border-right: 1px solid #c8c8c8;
    overflow: hidden;
    box-shadow: 1px 0 3px #e1e1e1;
    transition: flex-basis 0.25s;
}
.content-box-menu .content-box-menu__title {
    max-width: calc(100% - 35px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.content-box-components {
    flex-grow: 2;
    width: calc(100% - 180px);
}
.content-box-items {
    position: relative;
    width: 100%;
    min-height: 500px;
    overflow: hidden;
    padding: 0 44px;
}
.content-box-items--on-timeline {
    padding: 0 56px 0 32px;
}
.content-box-item {
    display: none;
    width: 100%;
    padding: 20px;
}
.content-box-item--active {
    display: block;
    padding: 0 0 40px;
}
