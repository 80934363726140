body {
    margin: 0;
}

.rv-login-box {
    width: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 50%);
}
