@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.timeline-interconnections {
    width: var(--rv-stations-width);
    min-width: var(--rv-stations-width);
    margin-right: calc(#{cssvar(spacing-1)} * 2 + var(--rv-segment-width));
    text-align: center;

    &:last-child {
        margin-right: 0;
    }

    &--in {
        align-self: flex-end;
        margin-bottom: 5px;
        margin-top: 0;
    }

    &-wrapper {
        position: relative;
        background: cssvar(grayscale-0-color);
        border: 1px solid cssvar(grayscale-30-color);
        border-radius: 5px;
        margin-top: 12px;
        padding: cssvar(spacing-1) 0;
    }
    &-container {
        display: flex;
        justify-content: flex-start;
        margin-bottom: cssvar(spacing-2);
    }
    &-hint {
        &:before {
            white-space: pre;
            text-align: left;
            width: 265px;
            font-size: 14px;
        }
    }
    &-item {
        width: var(--rv-interconnection-item-width);
        margin: 0 auto cssvar(spacing-half) auto;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &-item-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 400;
        letter-spacing: -0.25px;
        padding-left: cssvar(spacing-1);
    }
    &-tag {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: auto;
        border-color: cssvar(link-color);
    }
    &-tag-icon {
        margin-left: -2px;
        margin-right: 2px;
        width: 14px;
        height: 14px;
        vertical-align: middle;
    }
    &-link {
        display: inline-block;
        text-decoration: none;
        color: cssvar(link-color);
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 74px;
        vertical-align: middle;
    }
    &-status {
        display: inline-block;
        width: cssvar(spacing-1);
        height: cssvar(spacing-1);
        margin-bottom: 2px;
        vertical-align: middle;
        border-radius: 50%;

        &--success {
            background-color: cssvar(success-color);
        }
        &--warning {
            background-color: cssvar(warning-color);
        }
        &--danger {
            background-color: cssvar(danger-color);
        }
    }
    &-icon {
        position: absolute;
        bottom: -28px;
        left: 50%;
        transform: translateX(-50%);

        &--out {
            transform: translateX(-50%) rotate(-180deg);
            top: -24px;
            bottom: auto;
        }
    }
    &-btn {
        font-size: 14px;
        margin: -5px 0 -6px;
        border-radius: 0;
        border-bottom: 1px solid white;

        &:hover {
            border-bottom: 1px solid white!important;
        }

        &--open, &--open:hover {
            margin-bottom: cssvar(spacing-2);
            border-bottom: 1px solid cssvar(grayscale-30-color)!important;
        }
        &[class*='hcr-btn'] {
            &.timeline-interconnections-btn--danger {
                color: cssvar(danger-color);

                svg {
                    fill: cssvar(danger-color);
                }
            }
            &.timeline-interconnections-btn--warning {
                color: cssvar(warning-color);

                svg {
                    fill: cssvar(warning-color);
                }
            }
        }
    }
}
