.time-line-seats-locker {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    border: 1px dotted #c8c8c8;
    border-radius: 5px;
    background-color: #fff;
}

.time-line-seats-locker__form-open {
    text-align: center;
    cursor: pointer;
}

.time-line-seats-locker__form {
    position: absolute;
    top: auto;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    width: 341px;
    z-index: 99;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 3px 1px #c8c8c8;
}

.time-line-seats-locker__form-wrapper {
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        bottom: -9px;
        left: calc(50% - 7px);
        transform: translateY(-50%);
        border-style: solid;
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent #fff transparent;
        transform: rotate(-180deg);
        z-index: 2;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        bottom: -11px;
        left: calc(50% - 8px);
        transform: translateY(-50%);
        border-style: solid;
        border-width: 0 8.5px 10px 8.5px;
        border-color: transparent transparent #000 transparent;
        transform: rotate(-180deg);
        filter: blur(1px);
        opacity: 0.2;
        z-index: 1;
    }
}

.time-line-seats-locker__form--processing:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.6;
    animation: opacity-pulse 1s infinite alternate;
}

.time-line-seats-locker__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.time-line-seats-locker__input-wrapper {
    position: relative;
    margin: 0 6px;

    .lock-icon {
        position: absolute;
        top: 37px;
        left: 8px;
        z-index: 2;
    }

    .lock-input {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.time-line-seats-locker__control {
    display: flex;
    justify-content: space-around;
    text-align: center;

    &--bulk {
        padding: 2px 0 12px 0;
        line-height: 14px;
    }
}

.time-line-seats-locker__cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.time-line-seats-locker__error {
    display: inline-block;
    line-height: 1;
    color: #ff5700; /*fail-red*/
}

@keyframes opacity-pulse {
    from {
        opacity: 0.6;
    }
    to {
        opacity: 0.9;
    }
}
