.rideview-taurus-widget-wrapper {
    width: 298px;
}

@media (max-width: 600px) {
    .rideview-taurus-widget-wrapper {
        margin: 0 auto;
    }
}

.rideview-taurus-iframe {
    display: block;
    width: 250px;
    height: 150px;
    padding: 0;
    margin: -16px auto;
    border: none;
    background-color: transparent;
}

.rideview-taurus-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    z-index: 9999;
}

.rideview-taurus-bus-change-log {
    display: block;
    position: relative;
    margin: -10px 0 25px;
    text-align: right;
}

@media (max-width: 600px) {
    .rideview-taurus-bus-change-log {
        margin-top: 0;
    }
}
