.flix-page-dialog {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f7f7f7;
    z-index: 1001;
    overflow: auto;
}

.flix-page-dialog__header {
    color: #fff;
}
.flix-page-dialog__header [class*=header-bar__content] {
    max-width: initial;
}

.flix-page-dialog__back {
    cursor: pointer;
    color: inherit;
}
.flix-page-dialog__back svg {
    fill: #fff;
}

.flix-page-dialog__content {
    margin: 20px auto 20px auto;
    max-width: 1366px;
    padding: 0 24px;
}

.flix-page-dialog__grid {
    margin-top: 20px;
}
