@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.ride-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
        display: flex;
        min-width: 58%;
        align-items: center;
    }
    &__right {
        display: flex;
        min-width: 42%;
        align-items: center;
        justify-content: flex-end;
    }

    &__main-info {
        position: relative;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 2px;

        &--title {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &__uid {
            font-size: 21px;
        }
        &__id {
            font-size: 17px;
            font-weight: 700;
        }
    }

    &__del:hover &__copy-button {
        display: initial;
    }

    &__copy-button {
        display: none;
        position: absolute;
        top: -6px;
        right: -30px;
    }

    &__secondary-info {
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;

        &--title {
            font-size: 15px;
            letter-spacing: -0.3px;
        }
    }

    &__edit-icon {
        margin-left: 3px;
    }

    &__del {
        display: flex;
        height: 100%;
        align-items: center;
        flex-direction: column;
        margin-right: cssvar(spacing-3);
        padding-right: cssvar(spacing-3);
        border-right: 1px solid cssvar(grayscale-30-color);

        &--align-right {
            text-align: right;
            padding-right: cssvar(spacing-2);
        }

        &--fixed {
            min-width: 289px; // this is the optimal width to fit full ride id in one row
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &__line-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 250px;
        width: 100%;
    }

    .ride-info-tags {
        display: flex;
    }
}
