.rv-side-bar {
    &-control {
        position: fixed;
        top: 60px;
        right: 0;
        width: 48px;
        height: 100%;
        padding: 6px;
        background-color: #fff;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
        z-index: 6;
        @media (max-width: 1200px) {
            top: 108px;
        }
    }
    .sticky-header &-control {
        top: 0;
    }
}
