@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.timeline-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin: 0 0 cssvar(spacing-1);
    padding: 2px 4px 0;

    &:last-of-type {
        margin: 0 0 cssvar(spacing-3);
    }

    @media (max-width: 1024px) {
        display: block;
    }

    & button {
        font-size: inherit;
        font-weight: inherit;
        text-align: left;
    }

    &__total {
        color: cssvar(content-secondary-color);
        padding: 0 4px cssvar(spacing-1);
    }

    &__group {
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: space-between;

        &--full {
            width: 100%;
        }

        @media (max-width: 1024px) {
            width: 100%;
            margin: 10px 0 6px 0;
        }
    }

    &__tool {
        display: flex;
        margin-left: cssvar(spacing-3);

        @media (max-width: 1340px) {
            margin-left: cssvar(spacing-2);
        }

        fieldset {
            border: 0;
        }

        &--align-right {
            text-align: right;
            margin-right: 0;
        }
        &--align-left {
            text-align: left;
            margin-left: 0;
        }

        [class*='switch__input']:focus {
            & + [class*='-switch'] {
                outline: none;
            }
        }
        [class*='-btn'],
        [class*='-switch'] {
            &:hover {
                background-image: none !important;
            }
        }
    }

    &__cancel-button {
        // TODO: remove when cancel ride will be moved to the ride info actions
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        margin-right: 15px;
        border: 1px solid #ffad00;
        border-radius: 5px;
        cursor: pointer;
        color: #ffffff;
        background-color: #ffad00;

        &:hover {
            background-color: #c68600;
        }
    }

    &-icon-button {
        color: cssvar(grayscale-100-color);
        text-decoration: none;
        cursor: pointer;
    }
}
