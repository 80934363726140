@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.list-item {
    width: 100%;
    transition: all 0.35s;

    &__with-controls {
        display: flex;
        align-items: flex-start;
    }

    &__collapse-toggle {
        margin: 3px cssvar(spacing-2) 0 0;
        cursor: pointer;
    }

    &__select {
        margin: -3px cssvar(spacing-3) 0 0;
    }

    &__uid {
        max-width: 100%;
        padding: 0 8px 0 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__trip-number {
        font-weight: 400;
        font-size: 16px;
    }

    &__main-info {
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__secondary-info {
        max-width: 100%;
        margin-left: 32px;
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__date-time {
        min-width: 256px; // taken from the design
    }

    &__del {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-right: 1px solid cssvar(grayscale-30-color);
        padding-right: cssvar(spacing-4);
        margin-right: 5px;
        margin-left: -5px;

        &--no-border {
            border: 0;
            padding: 0 0 0 cssvar(spacing-2);
        }
    }

    &__button-wrapper {
        padding: 0 12px;

        @media (max-width: 1200px) {
            padding: 0;
        }
    }

    &__assignment {
        display: flex;
        align-items: center;
        max-width: 100%;
        margin: -7px 0 -11px -6px; // to better align with design
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
            display: inline-block;
            font-weight: normal;
            white-space: nowrap;
            line-height: 38px; // to fix text cut off
        }
    }

    &__assignment-icon-link {
        margin-left: 6px;
    }

    &__assignment-icon {
        fill: cssvar(primary-brand-color);
    }

    &__assignment-partner {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__assignment-more {
        font-size: 16px;
    }

    &__rideviewer-link {
        margin-left: -12px;
        margin-top: -4px;
    }

    &__assignment-btn {
        text-overflow: inherit;
    }

    &__assignments {
        max-height: 44px;
        overflow: hidden;
        transition: max-height 0.25s ease;

        &--more {
            max-height: 500px;
        }
    }

    &__tags {
        padding-top: cssvar(spacing-4);
        zoom: 0.8; // temporary solution only
    }

    &__wrapper {
        display: flex;
    }

    &--collapsible {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -11px 0;
    }

    &--expanded {
        margin: 0;

        .list-item__uid {
            margin-bottom: 6px;
        }
        .list-item__main-info {
            flex-flow: column;
            align-items: flex-start;
        }
        .list-item__secondary-info {
            margin-left: 0;
        }
        .list-item__rideviewer-link {
            margin-top: -7px;
        }
    }
}
