.search-panel {
  &__trigger {
    max-width: 600px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 0;
    margin: 0 auto;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    z-index: 99;
  }

  &__icon {
    margin-left: 10px;
  }
}