@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.p2b-item {
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
}

.filters-set {
    &__category {
        display: flex;
    }
}

.rv-action-log__accordion {
    margin-top: cssvar(spacing-2);
    margin-bottom: cssvar(spacing-2);
    background-color: cssvar(grayscale-10-color);
}

.temporary-banner {
    width: 59%;
    margin: cssvar(spacing-2) auto;
    padding: cssvar(spacing-3) 86px;
    text-align: center;
    font-size: 17px;
    border-radius: 15px;
    background-color: #ff943b;
}
