.batch-selection-form {
    display: flex;
    gap: 12px;

    &__row {
        display: flex;
        gap: 12px;
    }

    @media (max-width: 1460px) {
        flex-direction: column;
    }
}
