@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.timeline-station {
    position: relative;
    width: 189px;
    min-width: 189px;
    align-self: stretch;
    margin-bottom: cssvar(spacing-2);

    &-status {
        position: absolute;
        top: 8px;
        width: 100%;
        text-align: center;
        font-size: 14px;
        z-index: 2;

        &:before {
            content: '';
            display: inline-block;
            margin-right: 2px;

            [class*='--open'] & {
                width: 12px;
                height: 12px;
                margin-bottom: -2px;
                background-color: cssvar(primary-ui-color);
                border-radius: 50%;
            }
            [class*='--closed'] & {
                width: 16px;
                height: 16px;
                margin-bottom: -5px;
                clip-path: polygon(100% 0, 0 0, 50% 74%);
                background-color: cssvar(danger-dark-color);
            }
            [class*='--cancelled'] & {
                width: 10px;
                height: 10px;
                transform: rotate(-45deg);
                background-color: cssvar(primary-content-color);
            }
        }
    }
    &-box {
        position: relative;
        padding: cssvar(spacing-2) cssvar(spacing-1);
        margin-bottom: calc(#{cssvar(spacing-1)} / 2);

        &--first {
            padding-top: cssvar(spacing-6);

            [class*='--closed'] & {
                border-top: 4px solid cssvar(danger-dark-color);
            }
            [class*='--cancelled'] & {
                border-top: 4px solid cssvar(primary-content-color);
            }
        }
        &--disabled {
            opacity: 0.5;
            cursor: default;
            user-select: none;
        }
    }
    &-top-section {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
    }
    &-title {
        padding: 0 4px;
        text-align: center;
        min-height: 70px;
    }
    &-code,
    &-name {
        [class*='--closed'] & {
            color: cssvar(grayscale-50-color);
        }
        [class*='--cancelled'] & {
            color: cssvar(grayscale-50-color);
        }
    }
    &-name {
        line-height: cssvar(line-height-small);
    }
    &-schedule {
        text-align: center;
        &--highlight {
            color: cssvar(warning-dark-color);
        }
        &--strikethrough {
            text-decoration: line-through;
        }
    }
    &-time {
        color: inherit;
    }
    &-had {
        text-align: center;
        &--highlight {
            color: cssvar(warning-dark-color);
        }
        &__delays {
            text-align: left;
            padding: 0 cssvar(spacing-1);

            &-item {
                display: flex;
                justify-content: space-between;
            }
            &-name {
                text-align: left;
                width: 31%;
            }
            &-time {
                font-size: 16px;
                font-weight: bold;
                text-align: center;

                &--small,
                &--no-delay {
                    color: cssvar(success-dark-color);
                }
                &--medium {
                    color: cssvar(warning-dark-color);
                }
                &--big {
                    color: cssvar(danger-dark-color);
                }
            }
            &-mins {
                color: black;
                font-size: 12px;
                font-weight: normal;
            }
        }
        &-action {
            cursor: pointer;
            text-align: center;
            padding-top: 2px;
            font-size: 16px;

            &--disabled {
                opacity: 0.5;
                cursor: default;
                user-select: none;
            }

            & > span {
                padding-right: 3px;
            }
        }
    }
    &-delays {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        &__item {
            margin-bottom: cssvar(spacing-1);
        }
        &__tag {
            &--small-delay {
                svg {
                    fill: cssvar(success-dark-color);
                }
            }
            &--medium-delay {
                svg {
                    fill: cssvar(warning-dark-color);
                }
            }
            &--big-delay {
                svg {
                    fill: cssvar(danger-dark-color);
                }
            }
        }
    }
    &-pax-diff {
        display: flex;
        justify-content: space-between;
        margin: -26px 0 8px;
        &__in {
            color: cssvar(success-dark-color);

            svg {
                fill: cssvar(success-dark-color);
            }
        }
    }
    &-had-form {
        margin-left: cssvar(spacing-2);
        margin-right: cssvar(spacing-2);
        &__title {
            display: flex;
            justify-content: space-between;
        }
        &__wide {
            margin-left: calc(#{cssvar(spacing-2)} * -1);
            margin-right: calc(#{cssvar(spacing-2)} * -1);
            margin-bottom: cssvar(spacing-2);
        }
        &__tags {
            display: flex;
            margin-top: cssvar(spacing-1);
            justify-content: space-around;
        }
        &__bulk {
            display: flex;
            margin: cssvar(spacing-2) -4px 0 0;

            label {
                column-gap: 3px;

                &::before {
                    margin-right: 6px;
                }
            }
        }
        &__cta {
            display: flex;
            margin-top: cssvar(spacing-2);

            button {
                font-size: 14px;
                min-width: auto;
                width: 100%;
                height: 40px;
                line-height: 40px;
                padding-left: 4px;
                padding-right: 4px;
            }
        }
    }
}

.timeline-station-had {
    padding: 8px 0 4px 0;

    &-form {
        margin-left: 10px;
        margin-right: 10px;

        &__attention {
            position: absolute;
            right: 0;
            top: 3px;
            fill: cssvar(warning-color);
        }
        &__input-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
        }
        &__input input {
            width: 84px;
            padding: 0 2px 0 3px;
            font-size: 15px;

            &--warning {
                border-color: cssvar(warning-color);
            }
        }
        &__min-input {
            font-size: 15px;
            width: 67px;
        }
        &__desc {
            display: block;
            font-size: cssvar(font-size-small);
            font-weight: cssvar(font-weight-normal);
            line-height: cssvar(line-height-small);
            padding-top: cssvar(spacing-half);
            color: cssvar(warning-color);
            margin-bottom: calc(#{cssvar(spacing-2)} * -1);
        }
        &__pill {
            position: absolute;
            top: 4px;
            right: 0;
        }
    }
}

.icon {
    &-color {
        &--success {
            svg {
                fill: cssvar(success-dark-color);
            }
        }
        &--danger {
            svg {
                fill: cssvar(danger-color);
            }
        }
    }
}

.led-button {
    margin: 0 auto;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    &--open {
        background-color: cssvar(primary-ui-light-color);
        box-shadow: rgb(0 0 0 / 20%) 0 -1px 2px 1px,
            inset cssvar(primary-ui-color) 0 -1px 4px,
            cssvar(primary-ui-dark-color) 0 0px 2px;
    }
    &--closed {
        background-color: cssvar(grayscale-30-color);
        box-shadow: rgb(0 0 0 / 20%) 0 -1px 2px 1px,
            inset cssvar(grayscale-50-color) 0 -1px 4px,
            cssvar(grayscale-70-color) 0 0px 2px;
    }
    &--cancelled {
        background-color: cssvar(neutral-color);
        box-shadow: rgb(0 0 0 / 20%) 0 -1px 2px 1px,
            inset cssvar(neutral-color) 0 -1px 4px,
            cssvar(neutral-color) 0 0px 2px;
    }
}
