@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

$ride-view-max-width: 1318px;
$ride-view-max-width-middle: 1280px;
$ride-view-max-width-smaller: 1216px; // previous value is 1180px, live it here for a while

body {
    background: cssvar(grayscale-10-color);
}

.rideviewer-main-wrapper {
    background: cssvar(grayscale-10-color);
}

.ride-view-header {
    transition: transform 0.25s ease;
    z-index: 100;

    [class^='hcr-header__inner-'] {
        max-width: $ride-view-max-width;
    }

    &--sticky {
        transform: translateY(-100%);
    }
}

.sticky-header {
    .sub-navigation {
        position: fixed;
        width: 100%;
        top: 0;
        margin: 0;
        z-index: 5;
        background: cssvar(grayscale-10-color);
        border-bottom: 1px solid cssvar(grayscale-30-color);

        // any first element that goes after
        & + * {
            &:first-of-type {
                padding-top: 10px; // add some space after fixed elements
            }
        }
    }
}

.ride-view__site-content {
    width: 100%;
    margin: 80px auto 20px auto;
    padding: 0 0 90px;
}

@media (min-width: 600px) {
    .ride-view__site-content {
        margin-top: 120px;
    }
}
@media (min-width: 1200px) {
    .ride-view__site-content {
        margin-top: 80px;
    }
}

.ride-view__context-block {
    width: 100%;
    overflow: auto;
    margin-bottom: 30px;

    &--narrow {
        max-width: $ride-view-max-width;
        margin: 0 auto;
        transition: padding 0.25s ease;

        @media (max-width: 1420px) {
            max-width: $ride-view-max-width-middle;
        }
        @media (max-width: 1380px) {
            max-width: $ride-view-max-width-smaller;
        }
    }

    &--on-timeline {
        @media (max-width: 1320px) {
            padding-right: 22px;
        }
    }
}

.time-line__colored-box {
    padding: 5px 10px;
    line-height: 1.1;
    text-align: center;
    color: #ffffff;
    border-radius: 3px;
    background-color: #c8c8c8;
}

.time-line__colored-box--in-time {
    background-color: #73d700;
}

.time-line__colored-box--small-delay {
    background-color: #ffad00;
}

.time-line__colored-box--big-delay {
    background-color: #ff5700;
}

.time-line__colored-box--emphasize {
    color: #c64300;
}

.ride-view-badge {
    display: inline-block;
    padding: 0 6px;
    line-height: 1.5em;
    color: #ffffff;
    background-color: #c8c8c8;
    border-radius: 5px;
    text-align: center;
}

.ride-view-badge--absent {
    background-color: #ff5700;
}

.ride-view__col--align-bottom {
    display: flex;
    align-items: flex-end;
}

.ride-view-spinner {
    position: absolute;
    width: 20px;
    height: 20px;
}
.ride-view-spinner--inline {
    position: relative;
    display: inline-block;
}

.ride-view-spinner:after,
.ride-view-spinner--before:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid #73d700;
    border-right: 2px solid transparent;
    animation: spinner 0.6s linear infinite;
    z-index: 1;
}

.link-subtle {
    color: initial;
    text-decoration: none;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

hr {
    margin: cssvar(spacing-2) 0;
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: cssvar(primary-line-color);
}

.no-border-button {
    border: 0;

    &:hover {
        background: none !important;
    }
}

.rv-text-center {
    text-align: center;
}
.rv-text-right {
    text-align: right;
}
.rv-text-justify {
    text-align: justify;
    margin-left: cssvar(spacing-2);
    margin-right: cssvar(spacing-2);
}
