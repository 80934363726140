@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.celling-box {
    display: flex;
    border: 1px solid cssvar(primary-line-color);
    border-radius: cssvar(primary-border-radius);
    background-color: cssvar(box-bg-color);
    &__cell {
        padding: cssvar(spacing-2);
        flex-basis: 100%;
        border-right: 1px solid cssvar(primary-line-color);
        &:last-of-type {
            border: none;
        }
    }
}

.batch-status-box {
    min-height: 120px;
    &__value {
        font-weight: initial;
        color: cssvar(success-dark-color);
    }
}
